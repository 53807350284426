import { render, staticRenderFns } from "./MakbuzIndirIslemDetayView.vue?vue&type=template&id=145e576c&scoped=true"
import script from "./MakbuzIndirIslemDetayView.vue?vue&type=script&lang=ts"
export * from "./MakbuzIndirIslemDetayView.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "145e576c",
  null
  
)

export default component.exports